/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type PauseScribeStreamMutationVariables = Types.Exact<{
  input: Types.PauseScribeStreamInput;
}>;


export type PauseScribeStreamMutation = { __typename?: 'Mutation', pauseScribeStream?: { __typename?: 'PauseScribeStreamPayload', success: boolean, appointmentRecording?: { __typename?: 'AppointmentRecording', id: string } | null } | null };

export const PauseScribeStreamDocument = gql`
    mutation PauseScribeStream($input: PauseScribeStreamInput!) {
  pauseScribeStream(input: $input) {
    success
    appointmentRecording {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PauseScribeStreamMutationService extends Mutation<PauseScribeStreamMutation, PauseScribeStreamMutationVariables> {
    document = PauseScribeStreamDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }
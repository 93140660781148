@if (safeUrl$ | ngrxPush; as safeUrl) {
  <div
    [omgRecordEventOn]="{
      listenFor: 'init',
      eventName: 'com.onemedical.ui.timeline_document_pdf_loaded',
      eventData: { component: 'PDF viewer' },
    }"
  >
    <embed [src]="safeUrl" type="application/pdf" />
    <iframe height="100%" type="application/pdf" hidden [src]="safeUrl"></iframe>
  </div>
} @else {
  <div class="empty-tab-message">Loading document..</div>
}

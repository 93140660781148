// NOTE: These are global config objects that get injected
// - During build time via Webpack's DefinePlugin
// - During test via Jest global test setup
const envConfig = ENV_CONFIG;
const codeVersion = CODE_VERSION;

// This includes API keys and other secrets
const googleMapsAPIKey = GOOGLE_MAPS_API_KEY;

// Extract settings from global env config
const appSettings = envConfig.app;

const defaultPollingFrequency = 10000;
const versionChecker = appSettings.versionChecker || {};
const versionCheckerEnabled = !!versionChecker.targetUrl;
const sentryConfig = appSettings.sentry || {};
const rumConfig = appSettings.rum || {};
const pendo = appSettings.pendo || {};
const launchdarkly = appSettings.launchdarkly || {};
const appSync = appSettings.appSync || {};

// Strongly typed application enivronment settings
export const environmentConfig = {
  codeVersion: <string>codeVersion,
  versionChecker: {
    enabled: <boolean>versionCheckerEnabled,
    targetUrl: <string>versionChecker.targetUrl,
    pollingFrequency: <number>(
      (versionChecker.pollingFequency || defaultPollingFrequency)
    ),
  },
  awsBucket: <string>appSettings.awsBucket,
  mixPanel: {
    token: <string>appSettings.mixpanelToken,
    application: 'onelife-ui',
  },
  api: { baseUrl: <string>appSettings.api.baseUrl },
  search: {
    proxyHost: <string>appSettings.search.proxy_host,
    host: <string>appSettings.search.host,
    indexes: {
      allergic_reactions: <string>appSettings.search.indexes.allergic_reactions,
      allergies: <string>appSettings.search.indexes.allergies,
      consultant_autocomplete: <string>(
        appSettings.search.indexes.consultant_autocomplete
      ),
      contacts: <string>appSettings.search.indexes.contacts,
      filing_templates: <string>appSettings.search.indexes.filing_templates,
      health_goal_types: <string>appSettings.search.indexes.health_goal_types,
      lab_order_types: <string>appSettings.search.indexes.lab_order_types,
      medication_routes: <string>appSettings.search.indexes.medication_routes,
      message_templates: <string>appSettings.search.indexes.message_templates,
      note_templates: <string>appSettings.search.indexes.note_templates,
      problem_codes: <string>appSettings.search.indexes.problem_codes,
      problem_types: <string>appSettings.search.indexes.problem_types,
      task_assignees: <string>appSettings.search.indexes.task_assignees,
      text_templates: <string>appSettings.search.indexes.text_templates,
      vaccines: <string>appSettings.search.indexes.vaccines,
      clinical_timeline: <string>appSettings.search.indexes.clinical_timeline,
      clinical_timeline_alt: <string>(
        appSettings.search.indexes.clinical_timeline_alt
      ),
      procedure_types: <string>appSettings.search.indexes.procedure_types,
    },
  },
  auth0: {
    domain: <string>appSettings.auth0.domain,
    clientId: <string>appSettings.auth0.clientId,
    cacheLocation: appSettings.auth0.cacheLocation,
    httpInterceptor: appSettings.auth0.httpInterceptor,
    useFormData: false,
    authorizationParams: {
      audience: <string>appSettings.auth0.audience,
      scope: <string>appSettings.auth0.scope || 'openid profile email',
      connection: <string>appSettings.auth0.connection,
    },
  },
  adminApp: { host: <string>appSettings.adminApp.host },
  memberManagementUi: {
    host:
      <string>appSettings.memberManagementUi?.host ||
      <string>appSettings.adminApp.host,
  },
  legacyUi: {
    host: <string>appSettings.legacyUi.host,
  },
  externalDocumentsApp: {
    host: <string>appSettings.externalDocumentsApp?.host || '',
  },
  templatesApp: {
    host: <string>appSettings.templatesApp.host || '',
  },
  googleMaps: <string>googleMapsAPIKey,
  appSync: {
    apiUrl: <string>appSync.apiUrl,
    enabled: <boolean>appSync.enabled || false,
    region: <string>appSync.region || 'us-east-1',
  },
  sentry: {
    enabled: sentryConfig.enabled || false,
    // DSN is not considered to be a secret: https://forum.sentry.io/t/dsn-private-public/6297/3
    dsn: sentryConfig.dsn || undefined,
    environment: sentryConfig.environment || undefined,
  },
  rum: {
    enabled: rumConfig.enabled || false,
    identityPoolId: rumConfig.identityPoolId || undefined,
    applicationId: rumConfig.applicationId || undefined,
  },
  pendo: {
    apiKey: <string>pendo.apiKey,
  },
  launchdarkly: {
    clientSideId: <string>launchdarkly.clientSideId,
  },
};

import { environment } from '@environments/environment';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
let _rumClient: AwsRum | undefined;

const rumClient = (): AwsRum | undefined => {
  if (!_rumClient) {
    initializeRUM();
  }
  return _rumClient;
};

const initializeRUM = (): void => {
  if (!environment.rum.enabled) {
    return;
  }
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 0.1,
      identityPoolId: environment.rum.identityPoolId,
      endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
      telemetries: [],
      allowCookies: false, // cwrum sets some cookies with longer-lived user IDs and session IDs, not only do we not want those, they aren't important since we're a SPA
      enableXRay: false,
      pageIdFormat: 'HASH', // by setting this to HASH, we can ensure that the page URL is not logged
    };

    const APPLICATION_ID: string = environment.rum.applicationId;
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'us-west-2';

    _rumClient = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config,
    );
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
};

export const recordEvent = (eventType: string, eventData: object): void => {
  rumClient()?.recordEvent(eventType, eventData);
};

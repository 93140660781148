/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type ResumeScribeStreamMutationVariables = Types.Exact<{
  input: Types.ResumeScribeStreamInput;
}>;


export type ResumeScribeStreamMutation = { __typename?: 'Mutation', resumeScribeStream?: { __typename?: 'ResumeScribeStreamPayload', success: boolean, appointmentRecording?: { __typename?: 'AppointmentRecording', id: string } | null } | null };

export const ResumeScribeStreamDocument = gql`
    mutation ResumeScribeStream($input: ResumeScribeStreamInput!) {
  resumeScribeStream(input: $input) {
    success
    appointmentRecording {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResumeScribeStreamMutationService extends Mutation<ResumeScribeStreamMutation, ResumeScribeStreamMutationVariables> {
    document = ResumeScribeStreamDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }
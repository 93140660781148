import {
  inject,
  Injectable,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import {
  HealthscribeRecorderService,
  RECORDING_STATE,
} from './healthscribe-recorder.service';
import { AppointmentRecordingQueryService } from '@app/features/summaries/components/summaries/appointment-recording-query.service';

type RecordingStep =
  | 'transcriptAvailable'
  | 'recording'
  | 'dismissing'
  | 'dismissed'
  | 'aboutToGenerate'
  | 'generating'
  | 'default';

@Injectable({
  providedIn: 'root',
})
export class RecordingStepService {
  private privateRecordingStep = signal<RecordingStep>('default');
  private recordingId = signal<string | null>(null);
  private recordingService = inject(HealthscribeRecorderService);
  private recordingQueryService = inject(AppointmentRecordingQueryService);

  private CANCELLATION_TIMEOUT_MS = 5000;

  constructor() {}
  get writableBannerState(): WritableSignal<RecordingStep> {
    return this.privateRecordingStep;
  }

  get bannerSignal(): Signal<RecordingStep> {
    return this.privateRecordingStep;
  }

  get latestAppointmentRecordingId(): Signal<string | null> {
    return this.recordingId;
  }
  private cancellationTimeout: NodeJS.Timeout | undefined;

  cancelEndRecording(): void {
    if (this.cancellationTimeout) {
      clearTimeout(this.cancellationTimeout);
      this.cancellationTimeout = undefined;
      this.privateRecordingStep.set('recording');
      this.recordingService.resumeRecording();
    }
  }

  async endRecording(): Promise<void> {
    this.privateRecordingStep.set('aboutToGenerate');
    // Pass in "ENDED" state to indicate that recording is over and we shouldn't call pause mutation for streaming
    await this.recordingService.pauseRecording(RECORDING_STATE.ENDED);
    this.cancellationTimeout = setTimeout(async () => {
      // Remove the cancellation button before we end recording so that user can't resume recording
      // while we're trying to end.
      this.privateRecordingStep.set('generating');
      const recordingId = await this.recordingService.endRecording();
      this.recordingQueryService.setAppointmentId(recordingId);
    }, this.CANCELLATION_TIMEOUT_MS);
  }

  cancelDismiss(): void {
    if (this.cancellationTimeout) {
      clearTimeout(this.cancellationTimeout);
      this.cancellationTimeout = undefined;
      this.privateRecordingStep.set('default');
    }
  }

  dismiss(): void {
    this.privateRecordingStep.set('dismissing');
    this.cancellationTimeout = setTimeout(() => {
      this.privateRecordingStep.set('dismissed');
    }, this.CANCELLATION_TIMEOUT_MS);
  }
}

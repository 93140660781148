import { orderBy, sortBy } from 'lodash';
import { Problem } from './problems.type';

export type SortBy = 'alphabetical' | 'last-updated';
export type SortDirection = 'asc' | 'desc';
export type SortOrder = {
  by: SortBy;
  direction: SortDirection;
};
export const DefaultSortOrder: SortOrder = {
  by: 'alphabetical',
  direction: 'asc',
};

export function sort(problems: Problem[], order: SortOrder): Problem[] {
  switch (order.by) {
    case 'alphabetical':
      return orderBy(
        problems,
        problem => problem.problemType.clinicalDescription.toLowerCase(),
        order.direction,
      );
    case 'last-updated':
      return orderBy(
        problems,
        problem => Date.parse(problem.updatedAt),
        order.direction,
      );
  }
}

export const sortProblems = (problems: Problem[]): Problem[] => {
  return sortBy(problems, problem => [
    !problem.includedInMedicalHistory,
    problem.problemType.clinicalDescription.toLowerCase(),
  ]);
};

export const sortProblemsByCategory = (problems: Problem[]): Problem[] => {
  return sortBy(problems, problem => [
    !problem.needsRefresh,
    !problem.suspected,
    !problem.includedInMedicalHistory,
    problem.problemType?.clinicalDescription?.toLowerCase(),
  ]);
};

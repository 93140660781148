import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  EntityMetadataMap,
  getEntityMetadataInitialState,
  resetEntityMetadata,
  updateEntityMetadata,
} from '@app/utils';
import { newEntityId, Problem } from '../shared/problems.type';
import { ProblemAction, ProblemActionTypes } from './problems.actions';
import { DefaultSortOrder, SortOrder } from '../shared/problem-sort-utils';

export const problemsStatePath = 'problems';

export interface ProblemState extends EntityState<Problem> {
  loaded: boolean;
  loading: boolean;
  error: any;
  metadata: EntityMetadataMap<Problem>;
  lastDeleted: Problem | null;
  order: SortOrder;
}

function selectProblemId(problem: Problem): number {
  return problem.id;
}

export const adapter: EntityAdapter<Problem> = createEntityAdapter<Problem>({
  selectId: selectProblemId,
});

export const initialProblemsState: ProblemState = adapter.getInitialState({
  loaded: false,
  loading: false,
  error: null,
  lastDeleted: null,
  order: DefaultSortOrder,
  ...getEntityMetadataInitialState({}),
});

export function problemsReducer(
  state = initialProblemsState,
  action: ProblemAction,
): ProblemState {
  switch (action.type) {
    case ProblemActionTypes.GET_PROBLEMS: {
      return {
        ...state,
        loaded: false,
        loading: true,
        error: null,
      };
    }

    case ProblemActionTypes.GET_PROBLEMS_SUCCESS: {
      return adapter.setAll(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        error: null,
      });
    }

    case ProblemActionTypes.GET_PROBLEMS_ERROR: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: action.payload,
      };
    }

    case ProblemActionTypes.SAVE_PROBLEM:
    case ProblemActionTypes.SAVE_PROBLEM_AND_CREATE_PROCEDURE_INTERACTION:
    case ProblemActionTypes.SAVE_PROBLEM_AND_CREATE_PROCEDURE_ORDER: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(newEntityId, { pending: true }, state),
      };
    }

    case ProblemActionTypes.SAVE_PROBLEM_SUCCESS: {
      return adapter.addOne(action.payload, {
        ...state,
        loading: false,
        error: null,
        ...updateEntityMetadata(
          newEntityId,
          { pending: false, error: null },
          state,
        ),
      });
    }

    case ProblemActionTypes.SAVE_PROBLEM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          newEntityId,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case ProblemActionTypes.UPDATE_PROBLEM: {
      if (action.payload.problem.id === undefined) {
        throw new Error(
          `ProblemForm.id unexpectedly undefined when updating problem: ${action.payload.problem}`,
        );
      }
      return {
        ...state,
        loading: true,
        error: null,
        ...updateEntityMetadata(
          action.payload.problem.id,
          { pending: true },
          state,
        ),
      };
    }

    case ProblemActionTypes.UPDATE_PROBLEM_SUCCESS: {
      return {
        ...adapter.updateOne(
          {
            id: action.payload.id,
            changes: { ...action.payload },
          },
          state,
        ),
        loading: false,
        error: null,
        ...resetEntityMetadata(action.payload.id, state),
      };
    }

    case ProblemActionTypes.UPDATE_PROBLEM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case ProblemActionTypes.RESOLVE_PROBLEM: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(
          action.payload.problemId,
          { pending: true },
          state,
        ),
      };
    }

    case ProblemActionTypes.RESOLVE_PROBLEM_SUCCESS: {
      return {
        ...adapter.updateOne(
          { id: action.payload.id, changes: { ...action.payload } },
          state,
        ),
        loading: false,
        error: null,
        ...resetEntityMetadata(action.payload.id, state),
      };
    }

    case ProblemActionTypes.RESOLVE_PROBLEM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case ProblemActionTypes.REACTIVATE_PROBLEM: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(
          action.payload.problemId,
          { pending: true },
          state,
        ),
      };
    }

    case ProblemActionTypes.REACTIVATE_PROBLEM_SUCCESS: {
      return {
        ...adapter.updateOne(
          { id: action.payload.id, changes: { ...action.payload } },
          state,
        ),
        loading: false,
        error: null,
        ...resetEntityMetadata(action.payload.id, state),
      };
    }

    case ProblemActionTypes.REACTIVATE_PROBLEM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          { pending: false, error: action.payload },
          state,
        ),
      };
    }

    case ProblemActionTypes.REJECT_PROBLEM: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(
          action.payload.problemId,
          { pending: true },
          state,
        ),
      };
    }

    case ProblemActionTypes.REJECT_PROBLEM_SUCCESS: {
      return {
        ...adapter.updateOne(
          {
            id: action.payload.problemId,
            changes: { status: 6, active: false, suspected: false },
          },
          state,
        ),
        loading: false,
        error: null,
        ...resetEntityMetadata(action.payload.problemId, state),
      };
    }

    case ProblemActionTypes.REJECT_PROBLEM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.payload.problemId,
          {
            pending: false,
            error: action.payload,
          },
          state,
        ),
      };
    }

    case ProblemActionTypes.DELETE_PROBLEM: {
      return {
        ...state,
        loading: true,
        ...updateEntityMetadata(
          action.payload.problemId,
          { pending: true },
          state,
        ),
      };
    }

    case ProblemActionTypes.DELETE_PROBLEM_SUCCESS: {
      return {
        ...adapter.removeOne(action.payload.id, state),
        loading: false,
        lastDeleted: action.payload,
        ...resetEntityMetadata(action.payload.id, state),
      };
    }

    case ProblemActionTypes.DELETE_PROBLEM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        ...updateEntityMetadata(
          action.meta.id,
          {
            pending: false,
            error: action.payload,
          },
          state,
        ),
      };
    }

    case ProblemActionTypes.GET_PROBLEM_HISTORY: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case ProblemActionTypes.GET_PROBLEM_HISTORY_SUCCESS: {
      return adapter.updateOne(
        {
          id: action.payload.problemId,
          changes: {
            history: action.payload,
          },
        },
        {
          ...state,
          loading: false,
          error: null,
        },
      );
    }

    case ProblemActionTypes.GET_PROBLEM_HISTORY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case ProblemActionTypes.MODIFY_SORT_ORDER: {
      return {
        ...state,
        order: action.order,
      };
    }

    default: {
      return { ...state };
    }
  }
}

import { Injectable } from '@angular/core';
import { recordEvent } from './rum';

@Injectable({
  providedIn: 'root',
})
export class RumService {
  /**
   * Records an event to Cloudwatch RUM.
   *
   * @param eventName The name of the event in reverse DNS form. Should be prefixed with `com.onemedical.ui`.
   * Example: `com.onemedical.ui.page_loaded`.
   * @param eventData The metadata (if any) to add.
   */
  recordEvent(eventName: string, eventData: {}): void {
    return recordEvent(eventName, eventData);
  }

  /**
   * Sometimes it's hard to inject a service into a component (often because of old tests that make it annoying
   * to do so). In those cases, you can use this static method instead. Please don't overuse.
   *
   * @param eventName The name of the event in reverse DNS form. Should be prefixed with `com.onemedical.ui`.
   * @param eventData The metadata (if any) to add.
   */
  static recordEvent(eventName: string, eventData: {}): void {
    return recordEvent(eventName, eventData);
  }
}

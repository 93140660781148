import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NotificationsService } from '@app/core/notifications/shared/notifications.service';
import { NotificationAction } from '@app/core/notifications/shared/notifications.type';

export interface Chunk {
  response_chunk_html: string;
}

export interface Time {
  time: string;
}

@Component({
  selector: 'omg-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleComponent implements OnInit {
  constructor(
    private notificationsService: NotificationsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  chunk: string = '';
  latency: string = '';
  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.notificationsService
      .listen$<Chunk>(
        'TOUCAN_GENERATE',
        'TOUCAN_AWS_APPSYNC_DEMO',
        NotificationAction.CREATE,
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ({ payload }) => {
          this.chunk += payload.response_chunk_html;
          this.changeDetectorRef.detectChanges();
        },
        error => console.error(error),
      );

    this.notificationsService
      .listen$<Time>(
        'TOUCAN_GENERATE',
        'TOUCAN_AWS_APPSYNC_DEMO_TIME',
        NotificationAction.CREATE,
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ({ payload }) => {
          this.latency = payload.time;
          this.changeDetectorRef.detectChanges();
        },
        error => console.error(error),
      );
  }
}

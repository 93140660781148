import { Injectable } from '@angular/core';
import {
  createFeatureSelector,
  createSelector,
  select,
  Store,
} from '@ngrx/store';
import { sortBy } from 'lodash/fp';
import { createEntityMetadataSelectors } from '@app/utils';
import { sort, SortOrder, sortProblems } from '../shared/problem-sort-utils';
import { adapter, problemsStatePath, ProblemState } from './problems.reducer';
import { map, Observable } from 'rxjs';
import { Problem } from '../shared/problems.type';

export const selectProblemState =
  createFeatureSelector<ProblemState>(problemsStatePath);

const { selectEntities, selectAll } = adapter.getSelectors();

const selectProblemEntities = createSelector(
  selectProblemState,
  selectEntities,
);

const selectAllProblems = createSelector(selectProblemState, selectAll);

export const selectProblemById = createSelector(
  selectProblemState,
  (state, { id }) => state && state.entities && state.entities[id],
);

export const selectActiveProblems = createSelector(
  selectAllProblems,
  problems =>
    sortProblems(
      problems.filter(
        problem => problem.active || problem.unconfirmed || problem.suspected,
      ),
    ),
);

export const selectResolvedProblems = createSelector(
  selectAllProblems,
  problems =>
    sortProblems(
      problems.filter(
        problem =>
          !(problem.active || problem.unconfirmed || problem.suspected),
      ),
    ),
);

export const selectIncludedInMedicalHistoryProblems = createSelector(
  selectAllProblems,
  problems => problems.filter(problem => problem.includedInMedicalHistory),
);

export const selectActiveProblemHistoryIds = createSelector(
  selectIncludedInMedicalHistoryProblems,
  problems =>
    sortProblems(problems)
      .filter(problem => problem.active)
      .map(problem => problem.problemHistoryId),
);

export const selectResolvedProblemHistoryIds = createSelector(
  selectIncludedInMedicalHistoryProblems,
  problems =>
    sortProblems(problems)
      .filter(problem => !problem.active)
      .map(problem => problem.problemHistoryId),
);

export const selectProblemHistory = createSelector(
  selectProblemById,
  problem => problem && problem.history,
);

export const selectRecentProblem = createSelector(
  selectAllProblems,
  problems =>
    sortBy(problem => new Date(problem.updatedAt), problems).reverse()[0],
);

export const selectProblemHistoryId = createSelector(
  selectRecentProblem,
  problem => problem && problem.problemHistoryId,
);

const selectLastDeleted = createSelector(
  selectProblemState,
  state => state.lastDeleted,
);

const selectLoading = createSelector(
  selectProblemState,
  state => state.loading,
);

export const selectLoaded = createSelector(
  selectProblemState,
  state => state.loaded,
);

const selectOrder = createSelector(selectProblemState, state => state.order);

export const selectActiveProblemsAndOrder = createSelector(
  selectActiveProblems,
  selectOrder,
  (problems, order) => ({ problems, order }),
);

const selectActiveProblemsByCode = (problemCodes: string[]) => {
  return createSelector(selectActiveProblems, problems =>
    sortProblems(problems).filter(
      problem => problem.code && problemCodes.includes(problem.code),
    ),
  );
};

const { selectEntityWithMetadata } = createEntityMetadataSelectors(
  selectProblemState,
  selectProblemById,
);

const selectProblemWithMetadata = selectEntityWithMetadata;

@Injectable({ providedIn: 'root' })
export class ProblemSelectors {
  constructor(private store: Store<ProblemState>) {}

  get entities() {
    return this.store.pipe(select(selectProblemEntities));
  }

  get problems() {
    return this.store.pipe(select(selectAllProblems));
  }

  problem(id: number) {
    return this.store.pipe(select(selectProblemById, { id }));
  }

  get activeProblems() {
    return this.store.pipe(select(selectActiveProblems));
  }

  get resolvedProblems() {
    return this.store.pipe(select(selectResolvedProblems));
  }

  get includedInMedicalHistoryProblems() {
    return this.store.pipe(select(selectIncludedInMedicalHistoryProblems));
  }

  get activeProblemHistoryIds() {
    return this.store.pipe(select(selectActiveProblemHistoryIds));
  }

  get resolvedProblemHistoryIds() {
    return this.store.pipe(select(selectResolvedProblemHistoryIds));
  }

  problemMetadata(id: number) {
    return this.store.pipe(select(selectProblemWithMetadata, { id }));
  }

  problemHistory(id: number) {
    return this.store.pipe(select(selectProblemHistory, { id }));
  }

  get mostRecentProblem() {
    return this.store.pipe(select(selectRecentProblem));
  }

  get problemHistoryId() {
    return this.store.pipe(select(selectProblemHistoryId));
  }

  get lastDeleted() {
    return this.store.pipe(select(selectLastDeleted));
  }

  get loading() {
    return this.store.pipe(select(selectLoading));
  }

  get loaded() {
    return this.store.pipe(select(selectLoaded));
  }

  activeProblemsByCode(problemCodes: string[]) {
    const selector = selectActiveProblemsByCode(problemCodes);
    return this.store.pipe(select(selector));
  }

  activeProblemsSorted(): Observable<Problem[]> {
    return this.store.pipe(
      select(selectActiveProblemsAndOrder),
      map(({ problems, order }) => sort(problems, order)),
    );
  }

  order(): Observable<SortOrder> {
    return this.store.pipe(select(selectOrder));
  }
}

<omg-card-body>
  <omg-service-ticket
    *ngIf="workspace()?.expanded && workspace()?.mode === 'service-ticket' && summary()?.visitProcedureId"
    [visitProcedureId]="summary().visitProcedureId"
  ></omg-service-ticket>
  <omg-healthscribe-transcript
    *ngIf="workspace()?.expanded && workspace()?.mode === 'healthscribe-transcript'"
    [recording]="appointmentRecording()"
    [llmInsights]="llmInsights"
    [llmInsightsAreLoading]="llmInsightsAreLoading"
    [settings]="healthScribeSettings()"
    (contentCopied)="onAddToNote.emit($event)"
    (closed)="collapse()"
  ></omg-healthscribe-transcript>
</omg-card-body>

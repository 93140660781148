import {
  Directive,
  ElementRef,
  inject,
  input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { RumService } from '@app/core/monitoring/rum.service';

export type ListenerEvent = 'init' | 'destroy' | string;

interface RecordEventOnInput {
  listenFor: ListenerEvent;
  eventName: string;
  eventData?: object;
}

/**
 * Directive that you can use like `omgTrack`, but emits to Cloudwatch RUM.
 *
 * The argument is the event to listen for. `init` will fire on `ngOnInit`,
 * `destroy` will fire on `ngOnDestroy`. Anything else will set up a listener on the underlying DOM element.
 *
 * `eventData` is optional but recommended.
 */
@Directive({
  selector: '[omgRecordEventOn]',
  standalone: true,
})
export class RecordEventOnDirective implements OnInit, OnDestroy {
  directiveInput = input.required<RecordEventOnInput>({
    alias: 'omgRecordEventOn',
  });

  private rumService = inject(RumService);

  private renderer = inject(Renderer2);
  private elementRef = inject(ElementRef);

  private dispose = (): void => {};

  ngOnInit(): void {
    const input = this.directiveInput();

    if (input.listenFor === 'init') {
      this.trackEvent();
    } else if (input.listenFor !== 'destroy') {
      this.setupListener();
    }
  }

  ngOnDestroy(): void {
    const input = this.directiveInput();
    if (input.listenFor === 'destroy') {
      this.trackEvent();
    }

    this.dispose();
  }

  private setupListener(): void {
    this.dispose = this.renderer.listen(
      this.elementRef.nativeElement,
      this.directiveInput().listenFor,
      () => {
        this.trackEvent();
      },
    );
  }

  private trackEvent(): void {
    const input = this.directiveInput();
    this.rumService.recordEvent(input.eventName, input.eventData ?? {});
  }
}

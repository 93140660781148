import {
  Component,
  computed,
  HostBinding,
  inject,
  input,
  InputSignal,
  output,
  OutputEmitterRef,
} from '@angular/core';
import { Summary } from '../../shared/summaries.type';
import { PatientChartSelectors } from '@app/features/patient-chart/store/patient-chart.selectors';
import {
  GetRecordingInsightsQuery,
  GetRecordingLlmInsightsQuery,
  HealthScribeSettingsFragment,
} from '../summaries/get-recordings.onelife.generated';
import { PatientChartActions } from '@app/features/patient-chart/store/patient-chart.actions';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranscriptSectionCopyEvent } from '@app/features/healthscribe/transcript/transcript-section/transcript-section.component';
import { AppointmentRecordingQueryService } from '@app/features/summaries/components/summaries/appointment-recording-query.service';

export type SidebarMode = 'service-ticket' | 'healthscribe-transcript';

@Component({
  selector: 'omg-summary-sidebar',
  templateUrl: './summary-sidebar.component.html',
  styleUrls: ['./summary-sidebar.component.scss'],
})
export class SummarySidebarComponent {
  visibilityClass = computed(() =>
    this.workspace()?.expanded ? 'border-left' : 'hidden',
  );

  @HostBinding('class') get class(): string {
    return this.visibilityClass();
  }

  summary: InputSignal<Summary> = input.required<Summary>();
  appointmentRecording: InputSignal<
    GetRecordingInsightsQuery['appointmentRecording']
  > = input<GetRecordingInsightsQuery['appointmentRecording']>();
  healthScribeSettings: InputSignal<HealthScribeSettingsFragment | undefined> =
    input<HealthScribeSettingsFragment>();

  onAddToNote: OutputEmitterRef<TranscriptSectionCopyEvent> =
    output<TranscriptSectionCopyEvent>();

  patientChartActions = inject(PatientChartActions);
  patientChartSelectors = inject(PatientChartSelectors);
  workspace = toSignal(this.patientChartSelectors.workspace);

  constructor(
    private recordingQueryService: AppointmentRecordingQueryService,
  ) {}

  display(mode: SidebarMode): void {
    this.patientChartActions.expandWorkspace(true, mode);
  }

  toggle(mode: SidebarMode): void {
    const shouldExpand = !(
      this.workspace()?.expanded && this.workspace()?.mode === mode
    );
    this.patientChartActions.expandWorkspace(shouldExpand, mode);
  }

  collapse(): void {
    this.patientChartActions.expandWorkspace(false);
  }

  get llmInsights(): GetRecordingLlmInsightsQuery['appointmentRecording'] {
    return this.recordingQueryService.llmInsights();
  }

  get llmInsightsAreLoading() {
    return this.recordingQueryService.llmInsightsAreLoading();
  }
}
